html {
  background-image: url("images/light\ blue.jpg");
}

.App {
  text-align: center;
}


.header-logo img {
  width: 20vw;
  text-align: center;
  font-family: serif;
  margin-top: 5px;
}

.App-header {
  color: #004aad;
  text-align: center;
}

.header-app {
  font-size: 40px;
}

.description-text {
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  font-size: larger;

}

.App-landingpage {
  min-height: 100vh;
  width: 55vw;
  margin: 0rem auto;
}

.waitlist-form {
  margin: 0 auto;
  text-align: center;
}

form label {
  display: block;
  font-weight: bold;
  text-align: center;
  font-size: 40px;
  color: #004aad;
}


form input,
textarea,
button {
  display: block;
  width: 50%;
  padding: .5rem .8rem .5rem .8rem;
  margin: 1vw auto;
  border: 0;
  border-radius: 5px;
  font-size: 20px;
  box-sizing: border-box;
}

form button {
  background-color: #00C5A7;
  color: white;
  width: 48%;
  box-sizing: content-box;

}

form button:hover {
  cursor: pointer;
}

.swal2-confirm {
  width: 100px;
}

.swal2-popup .swal2-modal {
  font-size: x-large;
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
  .description-text {
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .header-app,
  form label {
    font-size: 22px;
  }

  .header-logo img {
    margin-bottom: 20px;
    margin-top: 40px;
  }

  form input,
  textarea,
  button {
    margin: 2vw auto;
    width: 50vw;
  }

  form button {
    width: 50vw;

  }

  input::placeholder {
    font-size: 14px;
  }

  button {
    font-size: 14px;
  }
}